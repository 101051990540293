import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import config from './aws-exports'
import { Amplify } from 'aws-amplify'

import { AmplifyProvider } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

Amplify.configure(config)


const theme = {
  name: 'my-theme',
  tokens: {
    // colors: {
    //   font: {
    //     primary: { value: 'red' },
    //     // ...
    //   },
    // },
    fonts: {
      default: {
        variable: {
          value:
            "'InterVariable', 'Inter var', 'Inter', -apple-system, BlinkMacSystemFont,'Helvetica Neue', 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans',sans-serif",
        },
        static: {
          value:
            "'Inter', -apple-system, BlinkMacSystemFont, 'Helvetica Neue','Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif",
        },
      },
    },
  },
};


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <AmplifyProvider theme={theme}>
    <App />
  </AmplifyProvider>
);




reportWebVitals();
